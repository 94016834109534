import { ApolloError } from '@apollo/client'
import {
  Flex,
  useMediaQuery,
  useToast,
  VStack,
  Link as ChakraLink,
  Box,
  Image
} from '@chakra-ui/react'
import { Text } from '../../typography'
import styled from '@emotion/styled'
import SideBarButton from 'components/SideBar/SideBarButton'
import { useAuthContext } from 'context/AuthProvider/index'
import { motion } from 'framer-motion'
import {
  Notifications,
  NotificationsDocument,
  useNotificationsLazyQuery,
  useMarkAsReadMutation,
  useGetArticleTypeOrderQuery
} from 'generated/graphql'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { color, ColorProps, height, HeightProps, space, SpaceProps } from 'styled-system'
import { theme, images } from 'theme'
import { ERROR_TOAST, responsiveWidth } from '../../constants'
import { useAppContext } from '../../context/AppProvider'
import { NotificationPopover, AuthButtons } from 'components'
import _ from 'lodash'
import { useSpring } from 'react-spring'
import UserIcons from 'components/Icons/UserIcon'
import './styles.css'

type PublicHeaderProps = ColorProps & {
  color?: string
  size?: number
  id?: string
  open?: boolean
  getLoggedInUser?: () => { name?: string; id: string }
}

type PublicHeaderContProps = SpaceProps &
  ColorProps &
  HeightProps & {
    color?: string
    open?: boolean
  }

const PublicHeaderCont = styled(motion.div)<PublicHeaderContProps>`
  ${space};
  ${color};
  ${height};
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '64px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: ${theme.colors.gray[950]};
  border-bottom: 1px solid ${theme.colors.gray[600]};
`

const emptyData: [] = []

const PublicHeader: React.FC<PublicHeaderProps> = ({ ...rest }) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const { drawerOpen, toggleDrawer } = useAppContext()
  const [notificationOpen, setNotificationOpen] = React.useState(false)
  const toast = useToast()

  const [notifications, setNotifications] = React.useState<Array<Notifications>>([])

  const { isAuthenticated } = useAuthContext()

  const { location } = useHistory()
  const { data, loading: gettingOrder } = useGetArticleTypeOrderQuery({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
  })

  const [getNotifications, { loading }] = useNotificationsLazyQuery({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: (data) => {
      setNotifications(data.notifications as Array<Notifications>)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [markAsRead, { loading: markAsReadLoading }] = useMarkAsReadMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: (data) => {
      setNotifications(data.markAsRead as Array<Notifications>)
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: NotificationsDocument }],
    update(cache, { data }) {
      cache.modify({
        fields: {
          myNotifications() {
            const newNotificationRef = cache.writeQuery({
              query: NotificationsDocument,
              data
            })

            return newNotificationRef
          }
        }
      })
    }
  })

  const newsCategories = _.orderBy(data?.getArticleTypeOrder || emptyData, 'menuOrder')

  const SubMenu = () => {
    if (gettingOrder) {
      return <></>
    }

    return (
      <Flex>
        {!isTabletOrMobile &&
          newsCategories.map((navtab, index) => {
            return (
              <ChakraLink
                bg="gray.600"
                width="auto"
                mr={index === newsCategories.length - 1 ? 0 : 3}
                key={navtab.orderId}
                href={`#${navtab?.orderId}`}
                whiteSpace="nowrap"
                borderRadius={8}
                paddingY={1}
                paddingX={2}
                textDecoration="none"
                _hover={{ color: 'secondary.500', cursor: 'pointer' }}
                zIndex={1}
              >
                <Text fontSize="sm" fontWeight="bold" color="white">
                  {navtab.title}
                </Text>
              </ChakraLink>
            )
          })}
      </Flex>
    )
  }

  const imageStyle = useSpring({
    config: { duration: 150 },
    opacity: drawerOpen ? 1 : 0
  })

  const isAuthNewsOrNews = location.pathname === '/news' || location.pathname === '/auth/news'

  const RenderLogoAndSubMenu = () => {
    return (
      <Flex
        ml={isTabletOrMobile ? 16 : 8}
        visibility={drawerOpen ? 'hidden' : 'visible'}
        alignItems="center"
        justifyContent={['flex-start']}
        position="relative"
        width={isAuthNewsOrNews ? ['20%', '20%', '100%', '100%', '100%', '100%'] : '20%'}
      >
        <Box
          className="logo-container"
          height="100%"
          width={'80px'}
          position={
            isAuthNewsOrNews
              ? ['relative', 'relative', 'relative', 'absolute', 'absolute', 'absolute']
              : 'relative'
          }
        >
          <Image
            width={'100%'}
            height="100%"
            style={imageStyle}
            src={images.gallopLogoWhite}
            alt="Gallop Logo"
            position={'relative'}
          />
        </Box>

        {isAuthNewsOrNews && (
          <Box className="sub-menu" ml={['20%', '10%', '13%', '18%', '15%', '10%']}>
            {' '}
            <SubMenu />
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <PublicHeaderCont padding={4} pb={2} height="max-content" {...rest}>
      <VStack width="100%" height="100%" alignItems="center" justifyContent="center">
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          {!drawerOpen && (
            <Flex width={'50px'} h="25px" alignItems="center" justifyContent="center">
              <SideBarButton color="#D7962D" open={drawerOpen} onClick={toggleDrawer} />
            </Flex>
          )}
          <RenderLogoAndSubMenu />
          <Flex width={['30%', '100%', '100%', '100%%', '100%', '80%']} justifyContent="flex-end">
            <Flex display={isTabletOrMobile ? 'none' : 'flex'}>
              <AuthButtons isAuthenticated={isAuthenticated} />
            </Flex>
            {isAuthenticated && (
              <Flex>
                <NotificationPopover
                  notificationOpen={notificationOpen}
                  setNotificationOpen={setNotificationOpen}
                  getNotifications={getNotifications}
                  notifications={notifications}
                  markAsRead={markAsRead}
                  loading={loading}
                  markAsReadLoading={markAsReadLoading}
                />

                <Link to="/auth/profile-management">
                  <UserIcons />
                </Link>
              </Flex>
            )}
          </Flex>
        </Flex>
      </VStack>
    </PublicHeaderCont>
  )
}

export default PublicHeader

PublicHeader.defaultProps = {
  bg: theme.opaqueBlue
}
