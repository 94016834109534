import { chunk } from 'lodash'
import * as React from 'react'
import { SpaceProps } from 'styled-system'
import { Col, Container, Row } from '../ResponsiveGrid'

type GridGeneratorProps = SpaceProps & {
  cols: 1 | 2 | 3 | 4 | 6 | 12
}

const GridGenerator: React.FC<GridGeneratorProps> = ({ cols, children, ...rest }) => {
  const colWidth = 12 / cols

  const rows = chunk(React.Children.toArray(children), cols)

  return (
    <Container data-testid="gridContainer" {...rest}>
      {rows.map((cols, i) => (
        <Row key={i}>
          {cols.map((col, j) => (
            <Col key={j} sm={12} md={colWidth} data-testid={`child${i * cols.length + j + 1}`}>
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  )
}

export default GridGenerator
