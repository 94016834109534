import React from 'react'
import { Box, Flex, Text, Icon } from '@chakra-ui/react'

type CirclTextProps = {
  size: number
  primaryColor: string
  secondaryColor: string
  text: string | number
  icon?: never
  iconSize?: never
}

type CircleIconProps = {
  size: number
  primaryColor: string
  secondaryColor: string
  text?: never
  icon: React.ElementType
  iconSize: number
}

type CircleOuterLayerProps = CirclTextProps | CircleIconProps

const CircleOuterLayer: React.FC<CircleOuterLayerProps> = ({
  size,
  secondaryColor,
  primaryColor,
  text,
  icon: IconComponent,
  iconSize
}): JSX.Element => {
  const MAIN_CIRCLE = size
  const SIZE = `${MAIN_CIRCLE}px`
  const INNER_SIZE = `${MAIN_CIRCLE - 8}px`
  const borderRadius = MAIN_CIRCLE / 2

  return (
    <Flex
      width={SIZE}
      height={SIZE}
      position="relative"
      zIndex={1}
      alignItems="center"
      justifyContent="center"
    >
      {/* Outer circle */}
      <Box
        width={INNER_SIZE}
        height={INNER_SIZE}
        borderRadius={borderRadius - 2}
        backgroundColor={primaryColor}
        position="absolute"
        zIndex={1}
      />
      {/* Inner circle */}
      <Box
        width={INNER_SIZE}
        height={INNER_SIZE}
        borderRadius={borderRadius - 2}
        borderColor={secondaryColor}
        borderWidth="8px"
        position="absolute"
        zIndex={1}
      />

      {IconComponent ? (
        <Icon as={IconComponent} color="black" boxSize={iconSize} borderRadius="full" zIndex={2} />
      ) : (
        <Text color="white" zIndex={2} position="inherit" margin="auto" textAlign="center">
          {text}
        </Text>
      )}
    </Flex>
  )
}

export default CircleOuterLayer
