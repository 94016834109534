import { Button, Flex, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { responsiveWidth } from '../../constants'
import Dots from './Dots'
import SliderContent, { Slide } from './SliderContent'
import { images } from 'theme'

const slides: Slide[] = [
  {
    backgroundImage: images.slider,
    title: 'News & Events',
    subTitle:
      'Access all the latest news in the horse racing industry. Here you can view, save and share articles. International and local event information can be found here'
  },
  {
    backgroundImage: images.slider,
    title: 'Private Networks',
    subTitle:
      'Access any SA Horse Racing private networks here. Simply sign in using your SA Horse Racing login details.'
  },
  {
    backgroundImage: images.slider,
    title: 'Statistics',
    subTitle: 'Get all the latest statistics on horses, trainers and jockeys all in one place.'
  },

  {
    backgroundImage: images.slider,
    title: 'Online Form Guide',
    subTitle:
      'View racecards and all the results of races taken place. You can also access media from the races here.'
  },
  {
    backgroundImage: images.slider,
    title: 'Betting',
    subTitle: 'Learn how to bet if you are a beginner and place your bets through affiliated sites.'
  }
]

const Slider: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const { push } = useHistory()

  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1)
    }, 5000)
    return () => clearInterval(interval)
  }, [activeIndex])

  const isLastSlide = activeIndex === slides.length - 1

  return (
    <Flex
      height={isTabletOrMobile ? '100vh' : '100%'}
      position="relative"
      margin="auto"
      overflow="hidden"
      width={isTabletOrMobile ? 'auto' : '100%'}
    >
      {isTabletOrMobile && (
        <Flex position="absolute" right={4} top={4}>
          <Button
            variant={isLastSlide ? 'outline' : 'link'}
            color="white"
            onClick={() => push('auth/news')}
          >
            {isLastSlide ? 'Continue' : 'Skip'}
          </Button>
        </Flex>
      )}
      <SliderContent activeIndex={activeIndex} slides={slides} isOnboarding />
      <Dots
        activeIndex={activeIndex}
        slides={slides}
        onClick={(activeIndex: number) => setActiveIndex(activeIndex)}
      />
    </Flex>
  )
}

export default Slider
