import React from 'react'
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  ModalBody,
  Box,
  Text,
  HStack
} from '@chakra-ui/react'
import { CircleOuterLayer } from 'components'
import { X, Check, Mail } from 'react-feather'

type Props = Omit<ModalProps, 'children'> & {
  isVerified: boolean
}

function EmailVerification({ onClose, isVerified, ...rest }: Props): JSX.Element {
  return (
    <Modal {...rest} size="xl" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent shadow="none" rounded="md" backgroundColor="gray.950">
        <ModalBody padding={4}>
          <Box>
            <HStack>
              <CircleOuterLayer
                primaryColor="#D7962D"
                secondaryColor="secondary.950"
                size={58}
                icon={isVerified ? Check : Mail}
                iconSize={4}
              />

              <Text fontSize={'18px'} fontWeight={600} color="white">
                {isVerified ? 'Registration Successful' : 'Sign Up Successful'}
              </Text>
            </HStack>

            <IconButton
              position="absolute"
              right={6}
              top={6}
              onClick={onClose}
              size="sm"
              aria-label="Close Modal"
              icon={<X />}
              backgroundColor="transparent"
              color="gray.400"
            />
          </Box>
          <Text fontWeight={400} color="gray.200" fontSize={'14px'} width={'80%'} mx="12%">
            {isVerified
              ? 'Your account has been verified!'
              : 'Please check your email for the verification instructions.'}
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <Button
            onClick={onClose}
            w="fit-content"
            backgroundColor="#D7962D"
            borderRadius="8px"
            mb={2}
            _hover={{ backgroundColor: 'secondary.950', cursor: 'pointer' }}
          >
            {isVerified ? 'Log In' : 'Close'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default EmailVerification
