import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { theme } from 'theme'
const Wrapper = styled(Flex)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  align-items: center;
  background-size: cover;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.gray[600]};
  opacity: 0.8;
`

const Loader: React.FC<FlexProps & { color?: string }> = (props) => {
  return (
    <Wrapper {...props}>
      <Spinner color="secondary.550" size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200">
        <span />
      </Spinner>
      <Text color="white">Loading...</Text>
    </Wrapper>
  )
}

export default Loader
