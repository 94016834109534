import React, { useEffect } from 'react'
import {
  Flex,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Link as ChakraLink,
  Center,
  Box,
  Image,
  VStack,
  HStack,
  Text
} from '@chakra-ui/react'

import { FillLoader, RevealFlex } from 'components'
import { useHistory } from 'react-router-dom'
import NotificationIcon from 'components/Icons/NotificationIcon'
import { images } from 'theme'
import './popover.css'
import { textLimiter } from '../../constants'
import { IoArrowForward } from 'react-icons/io5'

type NotificationPopoverProps = {
  notificationOpen: boolean
  setNotificationOpen: (open: boolean) => void
  getNotifications: () => void
  notifications: any[]
  markAsRead: (variables: any) => void
  loading: boolean
  markAsReadLoading: boolean
}

const NotificationPopover: React.FC<NotificationPopoverProps> = ({
  notificationOpen,
  setNotificationOpen,
  getNotifications,
  notifications,
  markAsRead,
  loading,
  markAsReadLoading
}) => {
  const history = useHistory()

  useEffect(() => {
    getNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Popover
      isLazy
      returnFocusOnClose={false}
      isOpen={notificationOpen}
      onClose={() => setNotificationOpen(!notificationOpen)}
      placement="bottom-end"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Box
          as="button"
          _hover={{
            cursor: 'pointer'
          }}
          marginRight={3}
          onClick={() => {
            setNotificationOpen(!notificationOpen)
            getNotifications()
          }}
          position="relative"
        >
          {notifications.length > 0 && (
            <Box
              position="absolute"
              height={2}
              width={2}
              backgroundColor="red"
              borderRadius="50%"
              left={3}
            ></Box>
          )}
          <NotificationIcon />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="gray.950"
          color="white"
          width={
            !notifications || notifications.length === 0
              ? '116%'
              : ['80%', '90%', '90%', '90%', '90%', '90%']
          }
          padding={4}
          borderColor="secondary.950"
          height={!notifications || notifications.length === 0 ? 40 : 80}
          top={1}
          mt={'2px'}
          left={
            !notifications || notifications.length === 0
              ? '0%'
              : ['3%', '20%', '20%', '20%', '20%', '20%']
          }
          borderRightRadius={0}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={8}
          position={'relative'}
        >
          {/* <PopoverHeader
            fontWeight={'extrabold'}
            fontSize="20px"
            border={'none'}
            alignItems={'center'}
          > */}
          <Flex
            top={2}
            position={'absolute'}
            flexDir={'row'}
            width="85%"
            right={0}
            left={0}
            py={1}
            mx="auto"
            justifyContent="space-beteween"
            alignItems="center"
          >
            <Text fontWeight={900} color="gray.200" fontSize="20px">
              Notifications
            </Text>
            <PopoverCloseButton
              data-testid="popover-close-button"
              color="gray.200"
              top={0}
              bottom={0}
              my="auto"
              // right={0}
            />
          </Flex>
          {/* </PopoverHeader> */}

          <PopoverBody
            className="custom-scrollbar"
            overflowY={notifications.length === 0 ? 'hidden' : 'visible'}
            overflowX="hidden"
            mt={2}
          >
            {notifications && notifications.length > 0 ? (
              <ChakraLink>
                <Flex
                  direction="row-reverse"
                  marginBottom={2}
                  my={3}
                  alignItems="center"
                  onClick={() =>
                    markAsRead({
                      variables: {
                        input: notifications.map((notification) => notification.id)
                      }
                    })
                  }
                >
                  <Text color="gray.400">Clear All</Text>
                </Flex>
              </ChakraLink>
            ) : null}
            {loading || markAsReadLoading ? (
              <Center>
                <FillLoader data-testid="loading-spinner" />
              </Center>
            ) : (
              notifications &&
              notifications.length > 0 &&
              notifications.map((notification) => (
                <Flex
                  align="center"
                  justify="center"
                  flexDirection="column"
                  h="fit-content"
                  key={notification.id}
                  my={3}
                >
                  <RevealFlex
                    flexDirection={'row'}
                    _hover={{ cursor: 'pointer' }}
                    bg="gray.800"
                    alignItems={'flex-start'}
                    borderRadius={12}
                    padding="16px"
                    w={'100%'}
                    justifyContent={'flex-start'}
                    onClick={() => {
                      markAsRead({
                        variables: {
                          input: [notification.id]
                        }
                      })
                      setNotificationOpen(false)
                      history.push(`/newsview/${notification?.article?.id}`, {
                        type: notification?.article?.title
                      })
                    }}
                  >
                    <Image
                      mr={2}
                      src={images.bellIcon}
                      alt="bell-icon"
                      width="32px"
                      height="32px"
                    />
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={14}>{notification?.title}</Text>
                      <Text fontSize={11}>{textLimiter(notification?.message, 59)}</Text>
                      <HStack alignItems="center">
                        <Text color="#D7962D" fontSize={14} fontWeight={600}>
                          Action
                        </Text>
                        <IoArrowForward color="#D7962D" />
                      </HStack>
                    </VStack>
                  </RevealFlex>
                </Flex>
              ))
            )}
            {(!notifications || notifications.length === 0) && !loading && !markAsReadLoading ? (
              <Flex align="center" justify="center" flexDirection="column" width="100%">
                <RevealFlex
                  flexDirection={'row'}
                  bg="gray.800"
                  alignItems={'center'}
                  borderRadius={12}
                  padding="16px"
                  w={'100%'}
                  my="10%"
                  justifyContent={'space-evenly'}
                >
                  <Image src={images.bellIcon} alt="bell-icon" width="32px" height="32px" />
                  <Text fontSize={14} color={'gray.200'}>
                    You have no notications
                  </Text>
                </RevealFlex>
              </Flex>
            ) : null}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default NotificationPopover
