import { Box, Flex, Text, FlexProps } from '@chakra-ui/react'
import React from 'react'

type EmptyListProps = FlexProps & {
  icon: JSX.Element
  text: string
}

export default function ({ icon, text, ...rest }: EmptyListProps): JSX.Element {
  return (
    <Flex
      {...rest}
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg={rest.bg ?? 'gray.100'}
    >
      <Box textAlign="center" py={8}>
        <Flex justifyContent="center">{icon}</Flex>
        <Text pt={4} fontWeight="bold" color={rest.color ?? 'gray.500'} fontSize="1.25rem">
          {text}
        </Text>
      </Box>
    </Flex>
  )
}
