import React from 'react'
import { VStack, Link, Text } from '@chakra-ui/react'

export type LinksProps = {
  links: Array<{
    title: string
    url: string
  }>
}

const LinkSections: React.FC<LinksProps> = ({ links }): JSX.Element => {
  return (
    <VStack spacing={4} align="flex-start">
      {links.map((link) => (
        <Link
          key={link.url}
          color={'gray.200'}
          href={link.url}
          textDecoration="none"
          fontWeight={600}
          _hover={{}}
        >
          <Text fontSize="lg">{link.title}</Text>
        </Link>
      ))}
    </VStack>
  )
}

export default LinkSections
