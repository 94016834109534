import { images } from 'theme'

export type NavDataProps = {
  siteUrl: string
  sitename: string
}

export const gallopUrl = images.gallopLogoWhite

export const affiliatedSites: NavDataProps[] = [
  {
    siteUrl: '/goldcircle/affiliatedGoldCircleLink',
    sitename: 'Gold Circle'
  },
  {
    siteUrl: '/nhra/affiliatedNhraLink',
    sitename: 'NHRA'
  },
  {
    siteUrl: '/iframe/bettingTabGold',
    sitename: 'tabGold'
  }
]

// TO DO: Add links to private network & online form guide
export const getStats: NavDataProps[] = [
  {
    siteUrl: '/news',
    sitename: 'News & Events'
  },
  {
    siteUrl: '/sa-horse-racing/SAhorseRacing',
    sitename: 'SA Horseracing'
  },
  {
    siteUrl: '/statistics/racingStatsLink',
    sitename: 'Statistics'
  },
  {
    siteUrl: '/betting',
    sitename: 'Betting'
  },
  {
    siteUrl: '/fixtures/fixtureIframeLink',
    sitename: 'Online Form Guide'
  }
]

export const forumStats: NavDataProps[] = [
  {
    siteUrl: '/africanbettingclan/affiliatedAfricanBettingClan',
    sitename: 'African Betting Clan'
  },
  {
    siteUrl: '/terms',
    sitename: 'Terms & Conditions'
  }
]

// TO DO: Add links to Gallop Social sites
export const socials: NavDataProps[] = [
  {
    siteUrl: 'https://www.instagram.com/theofficialgoldcircleracing/',
    sitename: images.instagram
  },
  {
    siteUrl: 'https://www.facebook.com/GoldCircleHorseracing',
    sitename: images.facebook
  },
  {
    siteUrl: 'https://twitter.com/GoldCircleRSA',
    sitename: images.twitter
  },
  {
    siteUrl: 'https://www.youtube.com/user/GoldCircleRacing',
    sitename: images.youtube
  }
]
