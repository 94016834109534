import React from 'react'

type UserIconsProps = {
  height?: number
  width?: number
  fill?: string
  color?: string
}

const UserIcons = ({
  height = 20,
  width = 20,
  fill = 'none',
  color = '#D7962D'
}: UserIconsProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
    >
      <g clipPath="url(#clip0_9158_22925)">
        <path
          d="M4.4296 16.1986C4.93653 15.0043 6.12012 14.1666 7.49935 14.1666H12.4993C13.8786 14.1666 15.0622 15.0043 15.5691 16.1986M13.3327 7.91663C13.3327 9.75758 11.8403 11.25 9.99935 11.25C8.1584 11.25 6.66602 9.75758 6.66602 7.91663C6.66602 6.07568 8.1584 4.58329 9.99935 4.58329C11.8403 4.58329 13.3327 6.07568 13.3327 7.91663ZM18.3327 9.99996C18.3327 14.6023 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6023 1.66602 9.99996C1.66602 5.39759 5.39698 1.66663 9.99935 1.66663C14.6017 1.66663 18.3327 5.39759 18.3327 9.99996Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9158_22925">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserIcons
