import { Flex, useMediaQuery } from '@chakra-ui/react'
import PublicHeader from 'components/PublicHeader'
import { useAuthContext } from 'context/AuthProvider'
import { useAnimation, Variants } from 'framer-motion'
import * as React from 'react'
import { LogOut } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { ColorProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { NavItem } from '../../navigation'
import SideBarItem from './SideBarItem'
import { Logo, LogoCont, MenuCont, Overlay, RenderWrapper } from './styles'
import { images } from 'theme'
import { useSpring } from 'react-spring'
import { AuthButtons } from 'components'
import { responsiveWidth } from '../../constants'

type SideBarProps = ColorProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  tooltipBg?: string
  tooltipColor?: string
}

const SideBar: React.FC<SideBarProps> = React.memo(
  ({ accentColor, children, hoverColor, navItems, tooltipBg, tooltipColor, closeOnNavigate }) => {
    const { drawerOpen, toggleDrawer } = useAppContext()
    const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
    const controls = useAnimation()

    const { isAuthenticated, logout } = useAuthContext()
    const { push } = useHistory()

    const handleLogout = () => {
      logout && logout()
      push('/')
    }

    React.useEffect(() => {
      if (drawerOpen) {
        controls.start('open')
      } else {
        controls.start('closed')
      }
    }, [drawerOpen, controls])

    const variants: Variants = {
      open: {
        x: 0,
        width: 250,
        transition: { staggerChildren: 0.05, delayChildren: 0.05, stiffness: 10, damping: 5 }
      },
      closed: {
        x: -250,
        transition: {
          stiffness: 80,
          staggerDirection: -1,
          staggerChildren: 0.1
        }
      }
    }

    const renderNavItems = () => {
      const items = navItems.map((props) => (
        <SideBarItem
          color="white"
          key={props.title}
          hoverColor={hoverColor}
          accentColor={accentColor}
          tooltipColor={tooltipColor}
          tooltipBg={tooltipBg}
          closeOnNavigate={closeOnNavigate}
          {...props}
        />
      ))
      isAuthenticated &&
        items.push(
          <SideBarItem
            color="white"
            key="logout"
            title={
              <span color="white" onClick={handleLogout}>
                Sign Out
              </span>
            }
            to="/news"
            icon={<LogOut color="white" onClick={handleLogout} />}
            hoverColor={hoverColor}
            accentColor={accentColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            closeOnNavigate={closeOnNavigate}
          />
        )
      return items
    }

    const imageStyle = useSpring({
      config: { duration: 150 },
      opacity: drawerOpen ? 1 : 0
    })

    return (
      <>
        <MenuCont
          bg="gray.950"
          flexDir="column"
          animate={controls}
          variants={variants}
          alignItems="flex-start" // Corrected typo here
          iconoffset={(64 - 20) / 2} // Calculate offset based on icon size
          justifyContent="flex-start"
          initial={{ width: drawerOpen ? 250 : 64 }}
        >
          {!isTabletOrMobile && (
            <LogoCont borderBottomColor="gray.700" open={drawerOpen}>
              <Flex width="100%" alignItems="flex-end" justifyContent="flex-start">
                <Flex flex={1} height="100%" alignItems="center" justifyContent="flex-end">
                  <Logo width="50%" height="100%" style={imageStyle} src={images.gallopLogoWhite} />
                </Flex>
              </Flex>
            </LogoCont>
          )}
          <Flex
            mb={1}
            flexDir="column"
            width="100%"
            maxHeight={isTabletOrMobile ? '100vh' : 'calc(100vh - 100px)'}
            overflowY={'scroll'}
            padding={2}
            css={{
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none'
            }}
          >
            {renderNavItems()}
            {isTabletOrMobile && (
              <Flex mx="auto" py={2}>
                <AuthButtons isAuthenticated={isAuthenticated} />
              </Flex>
            )}
          </Flex>
        </MenuCont>
        <RenderWrapper className="render-wrapper" pl={0}>
          <PublicHeader />
          {children}
          <Overlay
            onClick={toggleDrawer}
            pointerEvents={drawerOpen ? 'auto' : 'none'}
            animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
          />
        </RenderWrapper>
      </>
    )
  }
)

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'brand.500',
  accentColor: 'cyan.500'
}
