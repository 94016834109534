import React from 'react'
import { Flex, HStack, Box, useMediaQuery, Image, Text, BoxProps } from '@chakra-ui/react'
import moment from 'moment'
import { images } from 'theme'
import { BookMarkAndShare } from 'components'
import { Item } from 'utils'

import { responsiveWidth, textLimiter } from '../../constants'

type MediaCardProps = Omit<BoxProps, 'itemID'> & {
  itemId?: string
  imageUrl?: string
  title: string
  description: string
  publishedAt: string
  writer?: string
  channelTitle?: string
  hasDateOnTitle?: boolean
  rssLink?: string
  video?: Item
  onClick?: () => void
}

const MediaCard = ({
  imageUrl,
  title,
  description,
  publishedAt,
  writer,
  channelTitle,
  hasDateOnTitle = true,
  onClick,
  itemId,
  rssLink,
  video,
  ...rest
}: MediaCardProps): JSX.Element => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
  const _title = hasDateOnTitle ? title.slice(8) : title

  return (
    <Box
      {...rest}
      mx={rest.mx ?? [0, 0, 0, 4, 4, 4]}
      my={rest.my ?? [0, 0, 20, 20, 20, 20]}
      width="100%"
      height={['400px', null, null, '300px', '300px']}
      cursor={'pointer'}
      position="relative"
      data-testid="media-card"
      __css={{
        '&:hover .show-on-hover': {
          display: 'flex'
        }
      }}
    >
      {video ? (
        <BookMarkAndShare
          position="absolute"
          top="1rem"
          right="1rem"
          itemId={itemId as string}
          shareUrl={`https://www.youtube.com/${itemId}`}
          video={video}
          isMedia
        />
      ) : (
        <BookMarkAndShare itemId={itemId as string} shareUrl={rssLink as string} />
      )}

      <Image
        src={imageUrl ?? images.defaultNewsImage}
        minHeight={['200px', null, null, '250px', '300px']}
        maxHeight={['200px', null, null, '250px', '300px']}
        width="100%"
        objectFit="cover"
        alt="video cover image"
        borderRadius={15}
        loading="lazy"
        onClick={onClick}
      />
      <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
        <HStack color="secondary.950" fontWeight={600} fontSize={14} mt={5}>
          <Text>{writer ?? channelTitle ?? ''}</Text>
          <Text fontWeight={900}>•</Text>
          <Text fontSize="sm" ml={1}>
            {moment(publishedAt).format('DD MMM YYYY')}
          </Text>
        </HStack>

        <Flex flexDirection="column" alignItems="flex-start" textAlign={'justify'}>
          <Text fontWeight={600} fontSize="20px" color="white" my={2}>
            {textLimiter(_title, 40)}
          </Text>
          <Text color="gray.300" fontWeight={400} fontSize={16} textAlign={'justify'}>
            {textLimiter(description, isTabletOrMobile ? 100 : 60)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default MediaCard
