/* eslint-disable prettier/prettier */
import styled from '@emotion/styled'
import MotionFlex from '../MotionFlex'
import { FlexProps } from 'styled-system'
import { animated } from 'react-spring'

export const RenderWrapper = styled(MotionFlex)`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-direction: column;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

export const MenuCont = styled(MotionFlex)<{ iconoffset?: number }>`
  flex: 0 0 200px;
  position: fixed;
  max-width: 100%;
  min-height: 100vh;
  width: 250px;
  padding-left: 5px;
  z-index: 1299;
  & .sidebar-menu-icon {
    font-size: 21px;
    margin-left: ${(props) => `${props.iconoffset || 0}px`};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  & .nav-link-text-hidden > div > span {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

export const Overlay = styled(MotionFlex)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1295;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`

type LogoContProps = FlexProps & {
  open: boolean
}

export const LogoCont = styled(MotionFlex)<LogoContProps>`
  height: 70%;
  width: 70%;
  align-items: center;
  transition-property: width;
  justify-content: space-between;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin: 0 auto;
  padding: 15px 10px;

  & img {
    transition-property: visibility, opacity;
    opacity: ${(props: LogoContProps) => (props.open ? 1 : 0)};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: ${(props: LogoContProps) => (props.open ? 'visible' : 'hidden')};
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const Logo = styled(animated.img)`
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`
