/* eslint-disable prettier/prettier */
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { height, HeightProps } from 'styled-system'
import { theme } from '../../../theme'

const MotionLink = motion(NavLink)

type MenuItemProps = {
  color?: string
  bg?: string
  accentcolor?: string
  hovercolor?: string
  drawerOpen?: boolean
  isTermsAndConditions?: boolean
}

const { colors } = theme

export const Tooltip = styled(Flex)`
  opacity: 0;
  left: 70px;
  border-radius: 5px;
  visibility: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateX(10px);
  &:after {
    top: 50%;
    width: 0;
    margin: 0;
    height: 0;
    left: -5px;
    content: '';
    line-height: 0;
    position: absolute;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid ${theme.colors.gray[800]};
  }
`

export const MenuItem = styled(MotionLink)<MenuItemProps & HeightProps>`
  ${height};
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  justify-content: flex-start;
  transition: background 0.2s ease;
  border-radius: ${({ isTermsAndConditions }) => (isTermsAndConditions ? '0px' : '10px')};
  margin: 10px 3px;
  border-bottom: ${({ isTermsAndConditions }) =>
    isTermsAndConditions ? '1px solid gray' : 'none'};

  & .sidebar-nav-item-wrapper {
    & .icon-wrap img {
      filter: brightness(0) invert(1);
    }
  }

  &.active-nav-link {
    background: ${colors.gray[800]};
    color: ${colors.secondary[500]};

    & .sidebar-nav-item-wrapper {
      span {
        color: ${colors.secondary[500]};
        font-size: ${({ isTermsAndConditions }) => (isTermsAndConditions ? '16px' : '18px')};
        font-weight: bold;
      }
      & .icon-wrap img {
        filter: none;
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.2s ease;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${colors.secondary[500]};
    background: ${colors.gray[800]};
  }
  &:hover .sidebar-tooltip {
    opacity: 1;
    width: auto;
    padding: 5px 10px;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`
