import * as React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
  Flex,
  Text,
  useMediaQuery
} from '@chakra-ui/react'

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  WorkplaceIcon
} from 'react-share'
import './styles.css'
import { responsiveWidth } from '../../constants'

type InviteDataProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  shareUrl: string
}

function SocialShareModal({ isOpen, setIsOpen, shareUrl }: InviteDataProps): JSX.Element {
  const onClose = () => setIsOpen(false)

  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const shareButtons = [
    { Button: EmailShareButton, Icon: EmailIcon },
    { Button: FacebookShareButton, Icon: FacebookIcon },
    { Button: FacebookShareButton, Icon: FacebookMessengerIcon },
    { Button: LinkedinShareButton, Icon: LinkedinIcon },
    { Button: TumblrShareButton, Icon: TumblrIcon },
    { Button: WorkplaceShareButton, Icon: WorkplaceIcon },
    { Button: WhatsappShareButton, Icon: WhatsappIcon },

    { Button: RedditShareButton, Icon: RedditIcon },
    { Button: TelegramShareButton, Icon: TelegramIcon },
    { Button: MailruShareButton, Icon: MailruIcon },
    { Button: OKShareButton, Icon: OKIcon },

    { Button: PocketShareButton, Icon: PocketIcon },
    { Button: TwitterShareButton, Icon: TwitterIcon },
    { Button: ViberShareButton, Icon: ViberIcon },
    { Button: LivejournalShareButton, Icon: LivejournalIcon },

    { Button: LineShareButton, Icon: LineIcon },
    { Button: HatenaShareButton, Icon: HatenaIcon },
    { Button: VKShareButton, Icon: VKIcon },
    { Button: InstapaperShareButton, Icon: InstapaperIcon }
  ]

  return (
    <Modal isCentered isOpen={isOpen} size={'xl'} onClose={onClose} motionPreset="slideInRight">
      <ModalOverlay bg="gray.500" />
      <ModalContent bg="gray.950" padding={3}>
        <ModalCloseButton color="gray.400" />

        <ModalBody>
          <Flex justify="flex-start" mb="4">
            <Text fontSize="xl" fontWeight="extrabold" color="white">
              Share Link
            </Text>
          </Flex>
          <Flex
            data-testid="share-buttons-container"
            wrap="wrap"
            justify={isTabletOrMobile ? 'center' : 'flex-start'}
            gap="4"
          >
            {shareButtons.map(({ Button, Icon }, index) => (
              <Button key={index} url={shareUrl}>
                <Icon round size={40} />
              </Button>
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <ChakraButton onClick={onClose} color="black" bg="#D7962D">
            Close
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SocialShareModal
