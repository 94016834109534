import React from 'react'
import { Button, useMediaQuery } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { responsiveWidth } from '../../constants'

interface AuthButtonsProps {
  isAuthenticated: boolean | undefined
}

const AuthButtons: React.FC<AuthButtonsProps> = ({ isAuthenticated }) => {
  const history = useHistory()

  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const renderAuthButtons = () => {
    return (
      <>
        <Button
          bg="secondary.550"
          borderRadius={8}
          marginRight={4}
          onClick={() => history.push('/register')}
          fontSize={isTabletOrMobile ? 12 : 20}
          _hover={{ bg: 'secondary.950' }}
        >
          Sign Up
        </Button>
        <Button
          bg="white"
          onClick={() => history.push('/login')}
          fontSize={isTabletOrMobile ? 12 : 20}
        >
          Log In
        </Button>
      </>
    )
  }

  if (!isAuthenticated) {
    return renderAuthButtons()
  }

  return null
}

export default AuthButtons
