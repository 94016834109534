import { Center, Flex, useMediaQuery } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { H3, H4, H5, Text } from 'typography'
import { responsiveWidth } from '../../constants'

export type Slide = {
  logo?: React.ReactElement
  title: string
  subTitle: string
  backgroundImage?: string
}

type SliderProps = {
  activeIndex: number
  slides: Slide[]
  isOnboarding?: boolean
}

const BackgroundImage = styled.div<{ backgroundImage?: string; isOnboarding?: boolean }>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => (props.isOnboarding ? 'center' : 'top')};
  width: 100%;
  height: 100%;
  image-rendering: auto;
  background-image: url(${(props) => props.backgroundImage});
  border-radius: 8px;
`

const SliderContent: React.FC<SliderProps> = ({ activeIndex, slides, isOnboarding }) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  return (
    <Flex width="100%" padding={5}>
      {slides.map(({ title, subTitle, logo, backgroundImage }, index) => (
        <Center key={title} display={index === activeIndex ? 'flex' : 'none'} width="100%">
          {isOnboarding && (
            //glass effect
            <Flex
              position="absolute"
              bottom={5}
              left={0}
              right={0}
              width="94.5%"
              mx="auto"
              height="35%"
              bg="rgba(255, 255, 255, 0.1)"
              backdropFilter="blur(5px)"
              borderBottomLeftRadius={8}
              borderBottomRightRadius={8}
              zIndex={1}
            />
          )}
          <Flex
            position="fixed"
            textAlign="center"
            alignItems="center"
            justifyContent={isOnboarding ? 'flex-end' : 'flex-start'}
            flexDir="column"
            px={activeIndex === 2 || activeIndex === 4 ? '10rem' : 12}
            color="white"
            height={isTabletOrMobile ? '450px' : '500px'}
            zIndex={2}
          >
            <Flex mb={12}>{logo}</Flex>
            {isTabletOrMobile ? (
              <H5 mb={8} mt={2} fontWeight="bold">
                {title}
              </H5>
            ) : (
              <H3 fontWeight="bold" mt={12} mb={isOnboarding ? 2 : 8}>
                {title}
              </H3>
            )}
            {isTabletOrMobile ? (
              <Text fontWeight="bold" fontSize="sm">
                {subTitle}
              </Text>
            ) : (
              <H4 fontWeight="bold">{subTitle}</H4>
            )}
          </Flex>
          <BackgroundImage backgroundImage={backgroundImage} isOnboarding={isOnboarding} />{' '}
        </Center>
      ))}
    </Flex>
  )
}

export default SliderContent
