import { Flex, FlexProps, Box } from '@chakra-ui/react'
import { Banner, UnderConstruction } from 'components'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type PageWrapProps = FlexProps & {
  title?: string
  hasBanner?: boolean
  isUnderConstruction?: boolean
  hasBackButton?: boolean
}

const PageWrap: React.FC<PageWrapProps> = ({
  children,
  title,
  hasBanner,
  isUnderConstruction,
  hasBackButton,
  ...rest
}) => {
  return (
    <Box bg="gray.900" width="100%">
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Box mx="auto" width="97%">
          {' '}
          {title && hasBanner && <Banner title={title} hasBackButton={hasBackButton} />}
        </Box>
        <Flex {...rest}>
          {isUnderConstruction && <UnderConstruction />}
          {children}
        </Flex>
      </>
    </Box>
  )
}

PageWrap.defaultProps = {
  flex: 1,
  padding: [4, 6],
  height: '100%',
  flexDir: 'column',
  minHeight: '100vh',
  align: 'flex-start',
  justify: 'flex-start',
  bgColor: 'gray.900',
  hasBanner: false,
  width: '100%'
}

export default PageWrap
