import { Flex, FlexProps, useMediaQuery, Text, Button } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { responsiveWidth } from '../../constants'

const StyledFlex = styled(Flex)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
`

type BannerProps = FlexProps & {
  title: string
  leftElement?: React.ReactElement
  rightElement?: React.ReactElement
  hasBackButton?: boolean
}

const Banner: React.FC<BannerProps> = ({
  title,
  leftElement,
  rightElement,
  hasBackButton,

  ...rest
}): JSX.Element => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
  const history = useHistory()
  const onClickBack = () => {
    if (
      !isEmpty(history?.location.pathname) &&
      !history?.location?.pathname.includes('iframe/breedingLink') &&
      !history?.location?.pathname.includes('how-to-bet') &&
      !history?.location?.pathname.includes('stripe-reports')
    ) {
      history.replace(location.pathname)
    } else {
      history?.goBack?.()
    }
  }

  const programLink = history?.location?.pathname.includes('programmeLink')
  return (
    <Flex
      {...rest}
      p={5}
      width={'95%'}
      bg={'#3D3D3D'}
      borderRadius={8}
      mt={5}
      justifyContent={'space-between'}
      flexDirection={[isTabletOrMobile ? 'column' : 'row', 'row', 'row', 'row', 'row', 'row']}
    >
      {/* go back based on history if no onClickBack is provided */}
      {hasBackButton ? (
        <StyledFlex onClick={onClickBack}>
          <Text
            color="white"
            fontWeight={500}
            p={isTabletOrMobile ? 0 : 2}
            as="u"
            textDecoration="none"
            fontSize={32}
          >
            {location.pathname.includes('iframe/breedingLink') ? `Breeding` : title}
          </Text>
        </StyledFlex>
      ) : (
        <>
          {leftElement && <Flex marginRight={4}>{leftElement}</Flex>}
          <Text
            color="white"
            fontWeight="bold"
            fontSize={26}
            p={isTabletOrMobile ? 0 : 2}
            as="u"
            textDecoration="none"
            data-testid="banner-title"
          >
            {title}
          </Text>
        </>
      )}
      {rightElement && <Flex marginLeft={4}>{rightElement}</Flex>}
      {programLink && (
        <Button
          my={isTabletOrMobile ? 5 : 0}
          fontWeight="bold"
          color="black"
          backgroundColor="secondary.550"
          borderRadius="8px"
          fontSize={'16px'}
          width={['80%', '50%', '30%', '30%', '30%', '10%']}
          _hover={{
            bg: 'secondary.950',
            cursor: 'pointer'
          }}
          onClick={() => history.push('/sa-horse-racing/SAhorseRacing')}
        >
          Go To Trainer Portal
        </Button>
      )}
    </Flex>
  )
}

Banner.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  bg: 'brand.1000',
  m: 'auto'
}

export default Banner
