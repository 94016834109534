import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { theme } from 'theme'
import { responsiveWidth } from '../../constants'

type CardProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

type FlipCardProps = BoxProps & {
  renderFront: () => JSX.Element
  renderBack: () => JSX.Element
}

const Card = styled(motion.div)<CardProps>`
  ${space};
  ${color};
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  position: relative;
  color: ${theme.colors.gray[300]};
  position: absolute;
  width: 100%;
  height: 100%;
`

const animation = {
  initial: { rotateY: -180 },
  exit: { rotateY: -180 },
  animate: { rotateY: 0 },
  transition: {
    type: 'spring',
    damping: 20,
    stiffness: 80
  }
}

const AnimatedCard: React.FC = ({ children }) => {
  return (
    <Card
      {...animation}
      style={{
        WebkitBackfaceVisibility: 'hidden'
      }}
    >
      {children}
    </Card>
  )
}

const FlipCard: React.FC<FlipCardProps> = ({ renderFront, renderBack, ...boxProps }) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const [hovered, setHovered] = React.useState(false)
  const [tapped, setTapped] = React.useState(false)

  const toggleHover = () => setHovered(!hovered)

  const toggleTap = () => setTapped(!tapped)

  if (isTabletOrMobile) {
    return (
      <Box data-testid="flip-card" onClick={toggleTap} position="relative" {...boxProps}>
        <AnimatePresence>
          {tapped && (
            <AnimatedCard data-testid="front-side" key={1}>
              {renderFront()}
            </AnimatedCard>
          )}
          {!tapped && (
            <AnimatedCard data-testid="back-side" key={2}>
              {renderBack()}
            </AnimatedCard>
          )}
        </AnimatePresence>
      </Box>
    )
  }

  return (
    <Box
      data-testid="flip-card"
      position="relative"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...boxProps}
    >
      <AnimatePresence>
        {hovered && (
          <AnimatedCard data-testid="front-side" key={1}>
            {renderFront()}
          </AnimatedCard>
        )}
        {!hovered && (
          <AnimatedCard data-testid="back-side" key={2}>
            {renderBack()}
          </AnimatedCard>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default FlipCard

FlipCard.defaultProps = {
  width: '100%',
  height: '280px'
}
