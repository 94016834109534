import { Button, Flex, HStack, Image, VStack, useMediaQuery, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { PageWrap } from '../../layouts'
import { images } from '../../theme'
import { responsiveWidth } from '../../constants'
import Footer from 'containers/Footer'

const PageNotFound = (): JSX.Element => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
  const history = useHistory()

  return (
    <PageWrap title="404" position="relative">
      <Flex
        height="100vh"
        position="relative"
        flexDirection={isTabletOrMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="stretch"
        width="100%"
        my={2}
      >
        <VStack
          spacing={4}
          alignItems="flex-start"
          justifyContent="center"
          maxWidth="560px"
          width={isTabletOrMobile ? '100%' : '50%'}
          mx="auto"
          position="absolute"
          left={0}
          top={0}
          bottom={0}
        >
          <Text color="white" fontSize="60px" fontWeight="bold">
            Page Not Found
          </Text>
          <Text fontWeight={400} fontSize="20px" color="gray.200" lineHeight="30px">
            Sorry, the page you are looking for doesn&apos;t exist or has been moved. Here are some
            helpful links:
          </Text>
          <HStack>
            <Button
              onClick={() => history.goBack()}
              background="white"
              color="black"
              size="lg"
              width="100%"
            >
              Go back
            </Button>{' '}
            <Button
              onClick={() => history.push('/')}
              background="secondary.950"
              _hover={{ bg: 'secondary.550', cursor: 'pointer' }}
              color="black"
              size="lg"
              width="100%"
            >
              Take me home
            </Button>
          </HStack>
        </VStack>
        {!isTabletOrMobile && (
          <Flex
            bg="gray.950"
            flexDir="column"
            height="100vh"
            width="50%"
            maxW={isTabletOrMobile ? '100%' : '50%'}
            position="absolute"
            right={0}
            top={0}
            bottom={0}
          >
            <Flex flexDirection="column" overflow="hidden" height="100vh" width="100%">
              <Image height="100%" width="100%" src={images.notFoundPage} objectFit="cover" />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Footer />
    </PageWrap>
  )
}

export default PageNotFound
