import { Flex, Square } from '@chakra-ui/react'
import React from 'react'
import { Slide } from './SliderContent'

type DotProps = {
  activeIndex: any
  onClick: Function
  slides: Slide[]
}

const Dots: React.FC<DotProps> = ({ activeIndex, onClick, slides }: DotProps) => {
  return (
    <Flex
      width="fit-content"
      height="fit-content"
      position="absolute"
      justifyContent="center"
      zIndex={200}
      top={['85%', '90%']}
      backgroundColor="gray.950"
      padding={4}
      mx="auto"
      left={0}
      right={0}
      borderRadius={8}
    >
      {slides.map((_, index: React.Key | null | undefined) => {
        return (
          <Square
            width={5}
            height={1}
            borderRadius={4}
            backgroundColor={activeIndex === index ? 'secondary.500' : 'gray.200'}
            cursor="pointer"
            key={index}
            onClick={() => onClick(index)}
            marginRight={index === slides.length - 1 ? 0 : 4}
          />
        )
      })}
    </Flex>
  )
}

export default Dots
