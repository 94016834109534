import { Center } from '@chakra-ui/react'
import { useAppContext, useMobileOrTabletContext } from 'context/AppProvider'
import React, { Suspense } from 'react'
import { Redirect, RouteProps } from 'react-router'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { images, theme } from 'theme'
import { FillLoader, SocialShareModal } from '../components'
import SideBar from '../components/SideBar'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'

const noSideBarOrHeader = [
  '/',
  '/register',
  '/register/confirmed',
  '/login',
  '/login/confirmed',
  '/forgot-password',
  '/reset-password',
  '/confirm-email',
  '/confirm-reset',
  '/user-type',
  '/auth/onboarding',
  '/auth/user-type/onboardingComponent',
  '/onboarding',
  '/user-type',
  '/mobile-slides'
]

interface RouteType extends RouteProps {
  component: any
}
export type NavItem = {
  to: string
  title: string
  icon: string
  subMenu?: Array<{ to: string; title: string }>
}

const NAV_ITEMS: NavItem[] = [
  {
    to: '/news',
    title: 'Latest News',
    icon: images.newsIcon
  },
  {
    to: '/fixtures/fixtureIframeLink',
    title: 'Fixtures',
    icon: images.fixtureIcon
  },
  {
    to: '/galloptv/galloptvLink',
    title: 'Gallop TV',
    icon: images.galloptvIcon
  },
  {
    to: '/stripe-reports',
    title: 'Stipes Report',
    icon: images.stripeIcon
  },
  {
    to: '/programme/programmeLink',
    title: 'SA Horseracing',
    icon: images.saHorseIcon
  },
  {
    to: '/statistics/racingStatsLink',
    title: 'Statistics',
    icon: images.statsIcon
  },
  {
    to: '/breeders',
    title: 'Breeding',
    icon: images.bredIcon
  },
  {
    to: '/events',
    title: 'Events',
    icon: images.eventsIcon
  },
  {
    to: '/media-library',
    title: 'Media Library',
    icon: images.playIcon
  },
  {
    to: '/betting',
    title: 'Betting',
    icon: images.cashIcon
  },
  {
    to: '/faqs',
    title: 'FAQs',
    icon: images.faqIcon
  },
  {
    to: '/terms',
    title: 'Terms & Conditions',
    icon: images.termsIcon
  }
]

const AUTH_NAV_ITEMS: NavItem[] = [
  {
    to: '/auth/news',
    title: 'Latest News',
    icon: images.newsIcon
  },
  {
    to: '/auth/fixtures/fixtureIframeLink',
    title: 'Fixtures',
    icon: images.fixtureIcon
  },
  {
    to: '/auth/galloptv/galloptvLink',
    title: 'Gallop TV',
    icon: images.galloptvIcon
  },
  {
    to: '/auth/stripe-reports',
    title: 'Stipes Reports',
    icon: images.stripeIcon
  },
  {
    to: '/auth/programme/programmeLink',
    title: 'SA Horseracing',
    icon: images.saHorseIcon
  },
  {
    to: '/auth/statistics',
    title: 'Statistics',
    icon: images.statsIcon
  },
  {
    to: '/auth/breeders',
    title: 'Breeding',
    icon: images.bredIcon
  },
  {
    to: '/auth/events',
    title: 'Events',
    icon: images.eventsIcon
  },
  {
    to: '/auth/media-library',
    title: 'Media Library',
    icon: images.playIcon
  },
  {
    to: '/auth/betting',
    title: 'Betting',
    icon: images.cashIcon
  },
  {
    to: '/auth/faqs',
    title: 'FAQs',
    icon: images.faqIcon
  },
  {
    to: '/auth/terms',
    title: 'Terms & Conditions',
    icon: images.termsIcon
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()

  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route
    {...rest}
    render={() => (
      <Suspense fallback={<FillLoader color="black" />}>
        <Component />
      </Suspense>
    )}
  />
)

const Navigation = (): JSX.Element => {
  const { isMobileOrTablet } = useMobileOrTabletContext()
  const { isShareOpen, setIsShareOpen, shareUrl } = useAppContext()

  return (
    <Router>
      <Suspense
        fallback={
          <Center>
            <FillLoader bg="gray.100" />
          </Center>
        }
      >
        <Switch>
          <Route
            path="/auth"
            render={({ location: { pathname } }) => {
              if (noSideBarOrHeader.includes(pathname)) {
                return PRIVATE_ROUTES.map((route) => <PublicRoute key={route.path} {...route} />)
              }
              return (
                <SideBar
                  bg="hr"
                  color={'secondary.500'}
                  navItems={AUTH_NAV_ITEMS}
                  hoverColor="border"
                  accentColor="secondary.500"
                  borderColor="border"
                  closeOnNavigate={isMobileOrTablet}
                >
                  {PRIVATE_ROUTES.map((route) => {
                    return <PrivateRoute key={route.path} {...route} />
                  })}
                </SideBar>
              )
            }}
          />
          <Route
            path="/"
            render={({ location: { pathname } }) => {
              if (noSideBarOrHeader.includes(pathname)) {
                return PUBLIC_ROUTES.map((route) => <PublicRoute key={route.path} {...route} />)
              }
              return (
                <SideBar
                  bg={theme.colors.gray[800]}
                  color={'secondary.500'}
                  navItems={NAV_ITEMS}
                  hoverColor="border"
                  accentColor="secondary.500"
                  borderColor="border"
                  closeOnNavigate={isMobileOrTablet}
                >
                  {PUBLIC_ROUTES.map((route) => {
                    return <PublicRoute key={route.path} {...route} />
                  })}
                </SideBar>
              )
            }}
          />

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
        <SocialShareModal isOpen={isShareOpen} setIsOpen={setIsShareOpen} shareUrl={shareUrl} />
      </Suspense>
    </Router>
  )
}

export default Navigation
