import React from 'react'

type NotificationIconProps = {
  color?: string
  width?: number
  height?: number
}

const NotificationIcon = ({
  color = '#D7962D',
  width = 20,
  height = 20
}: NotificationIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.79447 17.5C8.38208 18.0186 9.15395 18.3333 9.99932 18.3333C10.8447 18.3333 11.6166 18.0186 12.2042 17.5M14.9993 6.66663C14.9993 5.34054 14.4725 4.06877 13.5349 3.13109C12.5972 2.19341 11.3254 1.66663 9.99932 1.66663C8.67324 1.66663 7.40147 2.19341 6.46379 3.13109C5.52611 4.06877 4.99932 5.34054 4.99932 6.66663C4.99932 9.24178 4.34972 11.0049 3.62404 12.1711C3.01193 13.1549 2.70587 13.6467 2.71709 13.7839C2.72952 13.9359 2.76171 13.9938 2.88414 14.0846C2.99471 14.1666 3.49315 14.1666 4.49004 14.1666H15.5086C16.5055 14.1666 17.0039 14.1666 17.1145 14.0846C17.2369 13.9938 17.2691 13.9359 17.2816 13.7839C17.2928 13.6467 16.9867 13.1549 16.3746 12.1711C15.6489 11.0049 14.9993 9.24178 14.9993 6.66663Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NotificationIcon
