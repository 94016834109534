import React from 'react'
import usePagination, { DOTS } from '../../hooks/usePagination'
import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { LuArrowLeft, LuArrowRight } from 'react-icons/lu'

interface PaginationProps {
  totalCount: number
  pageSize: number
  siblingCount?: number
  currentPage: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
  ...rest
}) => {
  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
    ...rest
  })

  if (!paginationRange) return null

  // If there are less than 2 times in pagination range we shall not render the component

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(Math.min(currentPage + 1, totalCount))
  }

  const onPrevious = () => {
    onPageChange(Math.max(currentPage - 1, 1))
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <Flex {...rest} alignItems="center" gridGap="2" width="100%" justifyContent="space-between">
      <Button
        color="white"
        onClick={onPrevious}
        isDisabled={currentPage === 1}
        backgroundColor="gray.800"
        leftIcon={<LuArrowLeft />}
      >
        Previous
      </Button>

      <HStack>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <Text data-testid="test-dots" key={pageNumber}>
                &#8230;
              </Text>
            )
          }

          return (
            <Button
              key={pageNumber}
              onClick={() => typeof pageNumber === 'number' && onPageChange(pageNumber)}
              isDisabled={pageNumber === currentPage}
              backgroundColor={pageNumber === currentPage ? 'gray.800' : 'transparent'}
              color={pageNumber === currentPage ? 'white' : 'gray.800'}
            >
              {pageNumber}
            </Button>
          )
        })}
      </HStack>

      <Button
        color="white"
        onClick={onNext}
        isDisabled={currentPage === lastPage}
        backgroundColor="gray.800"
        rightIcon={<LuArrowRight />}
      >
        Next
      </Button>
    </Flex>
  )
}

export default Pagination
