import { theme as chakraTheme } from '@chakra-ui/react'
import images from './images'
import '@fontsource/poppins'
// color palettes generated in https://smart-swatch.netlify.app/ with Gallop blue (#FDD930) as the main

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Poppins, sans-serif',
    mono: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    brand: {
      50: '#e3f3ff',
      100: '#bfd9f4',
      200: '#99bde7',
      300: '#72a3da',
      400: '#4b89ce',
      500: '#FDD930',
      600: '#24578d',
      700: '#173e66',
      800: '#082540',
      900: '#000d1c',
      1000: 'rgba(50,110,180,0.2)'
    },
    // brand used to be based off of #222222
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    },
    gray: {
      50: '#f5f5f5',
      100: '#E7E7E7',
      200: '#D1D1D1',
      300: '#B0B0B0',
      400: '#888888',
      500: '#6D6D6D',
      600: '#5c5c5c',
      700: '#464646',
      800: '#333333',
      900: '#222222',
      950: '#141414'
    },
    secondary: {
      100: '#FEFAD5',
      200: '#FEF4AC',
      300: '#FEED82',
      400: '#FDE563',
      500: '#FDD930',
      550: '#FBB940',
      600: '#D9B624',
      700: '#B79419',
      800: '#927410',
      900: '#795D09',
      950: '#B47521'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  opaqueBlue: 'rgba(49, 111, 180, 0.2)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { theme, images }
