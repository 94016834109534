import { IToast } from '@chakra-ui/react'
import { UploadFile } from '../generated/graphql'

export const APP_NAME = 'Gallop'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY ?? ''

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const WARNING_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'warning',
  isClosable: true,
  position: 'bottom-right'
}

export const responsiveWidth = '(max-width: 60em)'

type NavProps = {
  navUrl: string

  navName: string
}[]

export const navSaHorseRacingItems: NavProps = [
  {
    navUrl: '/programme/programmeLink',

    navName: 'Programme'
  },
  {
    navUrl: '/simdraw/simdraw',

    navName: 'Simdraw'
  },
  {
    navUrl: '/sa-horse-racing/SAhorseRacing',

    navName: 'Trainer Portal'
  }
]

export const navAuthSaHorseRacingItems: NavProps = [
  {
    navUrl: '/programme/programmeLink',

    navName: 'Programme'
  },
  {
    navUrl: '/simdraw/simdraw',

    navName: 'Simdraw'
  },
  {
    navUrl: '/sa-horse-racing/SAhorseRacing',

    navName: 'Trainer Portal'
  }
]

export const navBettingItems: NavProps = [
  {
    navUrl: '/how-to-bet',

    navName: 'How To Bet'
  },
  {
    navUrl: 'https://www.tabgold.co.za/',

    navName: 'TabGold'
  },
  {
    navUrl: 'https://www.trackandball.co.za/betting',

    navName: 'Track and Ball'
  }
]

export const navAuthBettingItems: NavProps = [
  {
    navUrl: '/auth/how-to-bet',

    navName: 'How To Bet'
  },
  {
    navUrl: 'https://www.tabgold.co.za/',

    navName: 'TabGold'
  },
  {
    navUrl: 'https://www.trackandball.co.za/betting',

    navName: 'Track and Ball'
  }
]

export const navBreedersItems: NavProps = [
  {
    navUrl: '/iframe/breedingLinkOne',

    navName: 'BSA'
  },

  {
    navUrl: '/iframe/breedingLinkTwo',

    navName: 'TBA'
  },

  {
    navUrl: '/iframe/breedingLinkThree',

    navName: 'CRS'
  }
]

export const navAuthBreedersItems: NavProps = [
  {
    navUrl: '/auth/iframe/breedingLinkOne',

    navName: 'BSA'
  },

  {
    navUrl: '/auth/iframe/breedingLinkTwo',

    navName: 'TBA'
  },

  {
    navUrl: '/auth/iframe/breedingLinkThree',

    navName: 'CRS'
  }
]

export const navMediaItems: NavProps = [
  {
    navUrl: '/media-library',

    navName: 'Video'
  },

  {
    navUrl: '/media-photo',

    navName: 'Photo'
  }
]

export const navAuthMediaItems: NavProps = [
  {
    navUrl: '/auth/media-library',

    navName: 'Video'
  },

  {
    navUrl: '/auth/media-photo',

    navName: 'Photo'
  }
]

export const saHorseRacingRoutes = [
  '/sa-horse-racing/SAhorseRacing',
  '/auth/sa-horse-racing/SAhorseRacing',
  '/programme/programmeLink',
  '/simdraw/simdraw'
]

export const authSaHorseRacingRoutes = [
  '/sa-horse-racing/SAhorseRacing',
  '/auth/sa-horse-racing/SAhorseRacing',
  '/auth/programme/programmeLink',
  '/auth/simdraw/simdraw'
]

export const bettingRoutes = [
  '/betting',
  '/auth/betting',
  '/iframe/bettingTabGold',
  '/iframe/bettingTracknBall',
  '/how-to-bet'
]

export const authBettingRoutes = [
  '/betting',
  '/auth/betting',
  '/auth/iframe/bettingTabGold',
  '/auth/iframe/bettingTracknBall',
  '/auth/how-to-bet'
]

export const breedersRoutes = [
  '/breeders',
  '/auth/breeders',
  '/iframe/breedingLinkOne',
  '/iframe/breedingLinkTwo',
  '/iframe/breedingLinkThree'
]

export const authBreedersRoutes = [
  '/breeders',
  '/auth/breeders',
  '/auth/iframe/breedingLinkOne',
  '/auth/iframe/breedingLinkTwo',
  '/auth/iframe/breedingLinkThree'
]

export const mediaLibraryRoutes = [
  '/media-library',
  '/media-photo',
  '/iframe/photoLibraryLinkOne',
  '/iframe/photoLibraryLinkTwo'
]

export const authMediaLibraryRoutes = [
  '/media-library',
  '/media-photo',
  '/auth/media-library',
  '/auth/media-photo',
  '/auth/iframe/photoLibraryLinkOne',
  '/auth/iframe/photoLibraryLinkTwo'
]

export type IconProps = {
  stroke?: string
  size?: number
  innerSize?: number
  fill?: string
  iconColor?: string
}

export const textLimiter = (text: string, limit: number): string => {
  return text.length > limit ? text.slice(0, limit) + '...' : text
}
