import { Flex, Image, Text, Box, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { gallopUrl } from './links'
import { LinksSection } from 'components'

type FooterProps = FlexProps

const Footer: React.FC<FooterProps> = ({ ...rest }): JSX.Element => {
  const year = new Date().getFullYear()

  const ProductLinks = [
    { title: 'Latest News', url: '/news' },
    { title: 'Fixtures', url: '/fixtures/fixtureIframeLink' },
    { title: 'Gallop TV', url: '/galloptv/galloptvLink' },
    { title: 'Breeding', url: '/breeding' },
    { title: 'Media Library', url: '/media-library' }
  ]

  const ReportLinks = [
    { title: 'Stipes Reports', url: '/stripe-reports' },
    { title: 'Statistics', url: '/statistics/racingStatsLink' }
  ]

  const ActivitiesLinks = [
    { title: 'SA Horseracing', url: '/programme/programmeLink' },
    { title: 'Betting', url: '/betting' },
    { title: 'Events', url: '/events' }
  ]

  const LegalAndResourcesLinks = [
    { title: `F&Q's`, url: '/rules' },
    { title: `T's & C's`, url: '/terms' }
  ]

  const SocialLinks = [
    { title: 'Twitter', url: 'https://twitter.com/goldcircle' },
    { title: 'Linkedin', url: 'https://www.linkedin.com/company/gold-circle/' },
    { title: 'Facebook', url: 'https://www.facebook.com/goldcircleracing' },
    { title: 'Instagram', url: 'https://www.instagram.com/theofficialgoldcircleracing/' }
  ]

  return (
    <Flex bg="gray.950" width={'100%'} flexDirection={'column'} p={6} {...rest}>
      <Flex
        width="100%"
        align="flex-start"
        justifyContent="space-between"
        mx="auto"
        pb={10}
        flexWrap="wrap"
      >
        <Box>
          <Text color="secondary.550" fontSize="lg" fontWeight={600} my={4}>
            Product
          </Text>
          <LinksSection links={ProductLinks} />
        </Box>

        <Box>
          <Text color="secondary.550" fontSize="lg" fontWeight={600} my={4}>
            Reporting
          </Text>
          <LinksSection links={ReportLinks} />
        </Box>

        <Box>
          <Text color="secondary.550" fontSize="lg" fontWeight={600} my={4}>
            Activities
          </Text>
          <LinksSection links={ActivitiesLinks} />
        </Box>

        <Box>
          <Text color="secondary.550" fontSize="lg" fontWeight={600} my={4}>
            Legal & Resources
          </Text>
          <LinksSection links={LegalAndResourcesLinks} />
        </Box>

        <Box>
          <Text color="secondary.550" fontSize="lg" fontWeight={600} my={4}>
            Social
          </Text>
          <LinksSection links={SocialLinks} />
        </Box>
      </Flex>
      <Box width="100%" borderBottom="1px solid" borderColor="secondary.550" />
      <Flex width="100%" justify="space-between" my={2} mx="auto" py={4} flexWrap="wrap">
        <Flex width={['80px', '90px']}>
          <Image width="100%" src={gallopUrl} alt="Gallop Logo" />
        </Flex>
        <Text color="gray.300">&copy; Copyright Gallop TV | All rights reserved | {year}</Text>
      </Flex>
    </Flex>
  )
}

export default Footer
